.page-content {
	@include clearfix;

	width: 620px;
	margin-right: 54px;
	float: left;

	p {
		strong {
			font-size: 18px;
			font-weight: $bold;
			color: $lime;
		}
	}

	.list {
		@include clearfix;

		margin-top: 30px;

		p {
			strong {
				font-size: 16px;
				font-weight: $bold;
				color: #fff;
			}
		}

		ul {
			li {
				margin-left: 18px;
				color: #fff;
			}

			&.bullet {
				li {
					margin-left: 0;
					list-style: none;
					background-image: url('../img/svg/svg--icon-tick.svg');
					background-repeat: no-repeat;
					background-size: 23px 23px;
					padding-left: 30px;
				}
			}
		}

		&--images {
			ul {
				width: 69%;
				float: left;
				margin-right: 20px;

				@media screen and (max-width: 650px) {
					width: 100%;
					float: none;
				}
			}

			img {
				float: left;
				border-radius: 158px;
				border: 5px solid $lime;
				margin-bottom: 15px;

				@media screen and (max-width: 650px) {
					margin-top: 15px;
					margin-right: 15px;
				}

				@media screen and (max-width: 405px) {
					float: none;
					margin: 15px auto 0;
				}
			}
		}
	}

	.cta {
		border: 3px solid #fff;
		padding: 20px;
		margin: {
			top: 40px;
			left: 30px;
			right: 30px;
		}

		p {
			font-size: 20px;
			font-weight: $bold;
			color: $lime;
			text-align: center;
			margin-top: 0;
		}

		@media screen and (max-width: 999px) {
			margin: {
				left: 0;
				right: 0;
			}
		}
	}

	.caveat {
		p {
			font-size: 12px;
		}
	}

	.key-staff {
		margin-top: 30px;
		font-family: $oswald;
		font-size: 35px;
		font-weight: $light;
		color: #fff;

		.staff {
			@include clearfix;

			margin-top: 20px;

			&-details {
				float: left;
				width: 63%;

				p {
					font-size: 14px;
				}

				@media screen and (max-width: 653px) {
					width: 100%;
					float: none;
				}
			}

			img {
				border-radius: 197px;
				border: 5px solid $lime;
				float: left;
				margin-right: 20px;

				@media screen and (max-width: 653px) {
					float: none;
					margin: 0 auto;
				}
			}
		}
	}

	@media screen and (max-width: 999px) {
		width: 100%;
		margin-right: 0;
		float: none;
	}
}