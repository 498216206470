.hero {
	@include clearfix;

	background-color: #fff;

	&-large {
		width: 666px;
		float: left;
		margin-right: 5px;

		img {
			width: 100%;
		}

		@media screen and (max-width: 998px) {
			float: none;
			width: 100%;
			margin-right: 0;
		}
	}

	&-small {
		width: 288px;
		float: right;

		img {
			width: 100%;
			margin-bottom: 5px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		@media screen and (max-width: 998px) {
			width: 100%;

			img {
				width: 33.33%;
				float: left;
				margin-bottom: 0;
			}
		}
	}
}

.home-intro {
	width: 580px;
	float: left;
	text-align: center;

	p {
		font-size: 18px;
		line-height: 1.5;
	}

	@media screen and (max-width: 998px) {
		width: 100%;
		float: none;
	}
}

.home-cta {
	width: 369px;
	background-color: $lime;
	padding: 20px;
	float: right;
	font-family: $roboto;
	color: #fff;
	text-align: center;

	a {
		text-decoration: none;
		color: #fff;
	}

	.regular {
		p {
			margin-top: 0px;
		}

		&.small {
			p {
				font-size: 19px;
			}
		}

		&.large {
			p {
				font-size: 21px;
			}
		}
	}

	.black {
		p {
			margin-top: 0px;
			font-weight: $black;
		}

		&.small {
			p {
				font-size: 26px;
			}
		}

		&.large {
			p {
				font-size: 28px;
			}
		}
	}

	@media screen and (max-width: 998px) {
		width: 100%;
		float: none;
		margin-top: 20px;
	}
}