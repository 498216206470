.svg {
	display: block;
	background-repeat: no-repeat;
	background-position: center center;

	&--inline {
		display: inline-block;
	}

	&--centered {
		position: absolute;
		top: -35%;
		left: 50%;
		transform: translate(-50%, 0%);
	}

	&--logo {
		width: 287px;
		height: 119px;
		background-image: url('../img/svg/svg--logo.svg');
	}

	&--claims {
		width: 64px;
		height: 53px;
		background-image: url('../img/svg/svg--icon-claims.svg');
	}

	&--mail {
		width: 41px;
		height: 27px;
		background-image: url('../img/svg/svg--icon-mail.svg');	
		transform: translateY(4px);
	}

	&--phone {
		width: 37px;
		height: 37px;
		background-image: url('../img/svg/svg--icon-phone.svg');
		transform: translateY(5px);
	}

	&--products {
		width: 57px;
		height: 60px;
		background-image: url('../img/svg/svg--icon-products.svg');
	}

	&--risk {
		width: 57px;
		height: 51px;
		background-image: url('../img/svg/svg--icon-risk.svg');
	}

	&--tick {
		width: 23px;
		height: 22px;
		background-image: url('../img/svg/svg--icon-tick.svg');
	}
}