.container {
    width: 100%;
    max-width: 1000px;
    padding: 0 20px;
    margin: 0 auto;
    box-sizing: border-box;
}

.main {
	background-color: $grey-dark;
}

.site-content {
	@include clearfix;

	padding: {
		top: 50px;
		bottom: 50px;
	}
}

.banner {
	height: 249px;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center; 
}

.page-title {
	width: 270px;
	padding: 20px;
	top: 0;
	bottom: 0;
	position: absolute;
	background-color: rgba($lime, .8);

	p {
		color: #fff;
		font-family: $oswald;
		font-weight: $light;
		font-size: 47px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		margin-top: 0;
		line-height: 1.2;
	}
}

.page-cta {
	clear: both;
	text-align: center;
	padding-top: 70px;

	p {
		font-family: $oswald;
		font-size: 27px;
		font-weight: $light;
		margin-top: 0;

		strong {
			font-weight: $light;
			color: $lime;
		}

		a {
			color: #fff;
			text-decoration: none;
		}
	}
}