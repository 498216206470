h1 {
	font-family: $oswald;
	font-size: 35px;
	font-weight: $light;
	color: #fff;
	line-height: 1.3;

	strong {
		font-weight: $bold;
		color: $lime;
	}
}

h2 {

}

p {
	font-family: $opensans;
	font-size: 16px;
	font-weight: $light;
	color: #fff;
	margin-top: 10px;
}

a {

}