.page-sidebar {
	@include clearfix;

	width: 286px;
	float: right;
	font-family: $oswald;
	font-weight: $light;
	font-size: 28px;
	color: #575756;

	.read-all {
		font-family: $oswald;
		font-weight: $light;
		font-size: 18px;
		color: #fff;
		text-decoration: none;
		text-align: center;
		display: block;
		margin-top: 15px;
	}

	@media screen and (max-width: 999px) {
		width: 100%;
		float: none;
		margin-top: 30px;
	}
}

.latest-news-side {
	background-color: $lime;
	margin-top: -50px;
	padding: {
		top: 55px;
		right: 20px;
		bottom: 20px;
		left: 20px;
	}

	@media screen and (max-width: 999px) {
		margin-top: 0;
		padding-top: 20px;
	}
}

.latest-news-side-list {
	@include clearfix;

	margin-top: 20px;

	.latest-news-side-item {
		margin-top: 12px;
		border-bottom: 1px solid #fff;
		padding-bottom: 10px;
		text-decoration: none;
		display: block;

		img {
			float: left;
			margin-right: 10px;
		}

		p {
			font-family: $opensans;
			font-size: 14px;
			margin-top: 0;
			line-height: 1.3;
		}

		.title {
			p {
				line-height: 1.2;
				font-size: 18px;
				font-weight: $semibold;
			}
		}

		.snippet {
			p {
				color: $grey-text;
			}
		}

		@media screen and (max-width: 999px) {
			display: inline-block;
			border: none;
			padding-bottom: 10px;
		}

		@media screen and (max-width: 970px) {
			width: 47%;
			margin-right: 2%;
		}

		@media screen and (max-width: 625px) {
			width: 100%;
			margin-right: 0;
		}
	}
}

.gallery {
	img {
		border: 3px solid #fff;
		margin-bottom: 15px;

		@media screen and (max-width: 999px) {
			width: 31.5%;
			display: inline-block;
			margin-right: 2%;

			&:nth-child(3n) {
				margin-right: 0;
			}
		}

		@media screen and (max-width: 774px) {
			width: 48.5%;

			&:nth-child(2n) {
				margin-right: 0;
			}

			&:nth-child(3n) {
				margin-right: 2%;
			}
		}

		@media screen and (max-width: 588px) {
			&:nth-child(2n) {
				margin-right: auto;
			}

			&:nth-child(3n) {
				margin-right: auto;
			}

			margin: 0 auto 15px;
			display: block;
		}
	}
}