.footer {

	.footer-links {
		@include clearfix;

		text-align: center;
		padding: {
			top: 55px;
			bottom: 55px;
		}

		@media screen and (max-width: 870px) {
			padding: {
				top: 30px;
			}	
		}

		.footer-link {
			width: 244px;			
			text-align: center;
			display: inline-block;
			border: 5px solid $grey-light;
			font-family: $oswald;
			font-weight: $light;
			font-size: 29px;
			color: #1D1D1B;
			position: relative;
			text-decoration: none;
			margin: {
				right: 15px;
				left: 15px;
			}
			padding: {
				top: 30px;
				bottom: 10px;
			}

			.svg {
				background-color: #fff;
			}

			@media screen and (max-width: 870px) {
				display: block;
				margin: 40px auto 0;
			}
		}
	}

	.footer-details {
		background-color: $grey-light;
		text-align: center;
		padding: {
			top: 15px;
			bottom: 15px;
		}

		p {
			color: $grey-text;
			margin-top: 4px;
			font-size: 15px;

			a {
				color: $grey-text;
				text-decoration: none;
			}
		}
	}
}