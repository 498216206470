.news-content {
	@include clearfix;

	margin-bottom: 30px;

	img {
		float: left;
		margin-right: 20px;

		@media screen and (max-width: 911px) {
			float: none;
			margin: 0 auto;
		}
	}

	.news {
		width: 65%;
		float: left;

		@media screen and (max-width: 911px) {
			width: 100%;
			float: none;
		}

		p {
			strong {
				color: $lime;
				font-size: 18px;
			}
		}

		.news-title {
			p {
				margin-top: -10px;
				font-family: $oswald;
				font-weight: $light;
				font-size: 38px;
				color: #fff;

				@media screen and (max-width: 911px) {
					margin-top: 5px;
				}
			}
		}

		a {
			font-family: $opensans;
			font-weight: $semibold;
			color: $lime;
			text-decoration: none;
			display: block;
			margin-top: 10px;

			&.back {
				font-family: $oswald;
				font-weight: $light;
				font-size: 27px;
				text-transform: uppercase;
				margin-top: 40px;
			}
		}
	}
}