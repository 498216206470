.header {
	@include clearfix;

	background-color: $grey-dark;
	padding: {
		top: 20px;
		bottom: 20px;
	}

	.logo {
		float: left;

		@media screen and (max-width: 800px) {
			float: none;
			margin: 0 auto;
		}
	}
	
	&-contacts {
		float: right;
		margin-top: 12px;

		@media screen and (max-width: 800px) {
			float: none;
			text-align: center;
		}
	}
	
		&-contact {
			font-family: $opensans;
			font-size: 23px;
			color: #DADADA;

			a {
				color: #dadada;
				text-decoration: none;
			}

			@media screen and (max-width: 480px) {
				font-size: 19px;
			}

			&--phone {
				font-weight: $light;
				font-size: 38px;

				@media screen and (max-width: 480px) {
					font-size: 28px;
				}
			}
		}
}

.nav {
	padding: {
		top: 10px;
		bottom: 10px;
	}

	ul {
		text-align: center;

		li {
			position: relative;
			list-style: none;
			display: inline-block;
			margin: {
				right: 12px;
				left: 12px;
			}

			a {
				font-family: $oswald;
				font-size: 20px;
				font-weight: $light;
				color: $grey-text;
				text-decoration: none;
				text-transform: uppercase;

				@media screen and (max-height: 460px) {
					font-size: 18px;
				}
			}

			ul {
				display: none;
			}

			&:hover {
				ul {
					display: block;
					position: absolute;
					z-index: 2;
					background-color: #fff;
					left: 0;
					width: 250px;
					padding-top: 6px;
					margin-left: -12px;
					margin-right: 0;
					text-align: left;
				}
			}
		}
	}

	@media screen and (max-width: 990px) {
		display: none;

		&.show {
		    display: block;
		    background-color: #fff;
		    position: fixed;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    right: 0;
		    z-index: 10;

		    ul {
		    	position: absolute;
		    	top: 50%;
		    	left: 50%;
		    	transform: translate(-50%, -50%);

		    	li {
		    		display: block;
		    		margin: {
		    			top: 10%;
		    			bottom: 10%;
		    			right: 0;
		    			left: 0;
		    		}

		    		ul {
		    			width: 100%;
		    			position: relative;
		    			margin-top: 10%;
		    			margin-bottom: 0%;
		    			top: auto;
		    			left: auto;
		    			transform: translate(0, 0);
		    			display: block;

		    			li {
		    				width: 100%;
		    				display: block;
		    				margin-top: 0%;
		    				margin-bottom: 0%;
		    			}

		    			@media screen and (max-height: 700px) {
		    				margin-top: 5%;
		    			}

		    			@media screen and (max-height: 540px) {
			    			margin-top: 2%;
			    		}
		    		}

		    		&:hover {
		    			ul {
		    				padding-top: 0;
		    				margin-left: 0;
		    				margin-right: 0;
		    				text-align: center;
		    			}
		    		}

		    		@media screen and (max-height: 700px) {
		    			margin-top: 5%;
		    			margin-bottom: 5%;
		    		}

		    		@media screen and (max-height: 540px) {
		    			margin-top: 2%;
		    			margin-bottom: 2%;
		    		}
		    	}
		    }
		}
	}
}

.burger {
    display: none;

    @media screen and (max-width: 990px) {
    	text-align: center;
        margin: 10px auto;
        display: block;
        cursor: pointer;
        font-family: $oswald;
        font-size: 20px;
        font-weight: $light;
        color: $grey-text;

        &--close {

            &:after {
            	content: 'Close';
            }
        }

        &:hover {
            cursor: pointer
        }
    }
}