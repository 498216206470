.location {
	@include clearfix;

	margin-top: 40px;
	
	.details {

		float: left;

		strong {
			color: $lime;
		}

		a {
			text-decoration: none;
			color: #fff;
		}

		@media screen and (max-width: 850px) {
			float: none;
		}
	}

	.map {
		margin-left: 20px;
		float: right;

		@media screen and (max-width: 850px) {
			width: 100%;
			float: none;
			margin-top: 10px;
			margin-left: 0;

			.cmsmap {
				width: 100% !important;
				height: 300px !important;
			}
		}
	}
}

.key-contacts {
	max-width: 450px;
	font-family: $opensans;
	font-weight: $bold;
	font-size: 18px;
	color: $lime;
	margin-top: 40px;

	a {
		color: #fff;
		text-decoration: none;
	}

	.contact {
		@include clearfix;

		margin-top: 30px;

		&:first-child {
			margin-top: 0;
		}

		.contact-details {
			float: left;
			margin-right: 30px;
		}

		img {
			float: right;
			border: 3px solid $lime;
			border-radius: 109px;

			@media screen and (max-width: 445px) {
				margin-top: 10px;
			}
		}
	}
}