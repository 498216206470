/* Fonts */
$opensans: 'Open Sans', sans-serif;
$opensanscondensed: 'Open Sans Condensed', sans-serif;
$roboto: 'Roboto', sans-serif;
$oswald: 'Oswald', sans-serif;

$light: 300;
$semibold: 600;
$bold: 700;
$black: 900;

/* Colours */
$grey-dark: #3C3C3B;
$grey-light: #EDEDED;
$grey-text: #706F6F;
$lime: #ADCE34;